import { useRef, useState, useEffect } from "react";

// deps
import useWebSocket from "react-use-websocket";
import { default as MicrophoneStream } from "microphone-stream";
import { config_var } from "./config";
var getUserMedia = require("get-user-media-promise");

const OVERRIDE_TIMEOUT_POPUP: boolean = !!config_var("OVERRIDE_TIMEOUT_POPUP");

const TORRAP_URL = config_var("TORRAP_URL");
if (TORRAP_URL === undefined)
  throw Error("Missing enrionment variable TORRAP_URL");

const F32ToInt16 = (input: any) => {
  // Convert floating-point 32-bit PCM audio to integer 16 bit
  var output = new DataView(new ArrayBuffer(input.length * 2)); // length is in bytes (8-bit), so *2 to get 16-bit length
  for (var i = 0; i < input.length; i++) {
    var multiplier = input[i] < 0 ? 0x8000 : 0x7fff; // 16-bit signed range is -32768 to 32767
    output.setInt16(i * 2, (input[i] * multiplier) | 0, true); // index, value ("| 0" = convert to 32-bit int, round towards 0), littleEndian.
  }
  return output.buffer;
};

type SpeechToTextHook = {
  lastSTTResult: null | string;
  init: () => void;
  initialized: boolean;
};

const useSpeechToText = (
  muted: boolean,
  resetTimeout: any,
  clearTimeout: any
): SpeechToTextHook => {
  // This component streams microphone data over websockets to torrap, and updates
  // lastSTTResult when speech-to-text results are received

  const micStream = useRef(null);
  const [lastSTTResult, setLastSTTResult] = useState<string | null>(null);

  const [sampleRate, setSampleRate] = useState<null | number>(null);
  const [socketUrl, setSocketUrl] = useState<null | string>(null);
  const [initialized, setInitialized] = useState(false);

  // We need the muted data out of the react stat as well, for the
  // data streaming code :/
  const mutedRef = useRef<boolean>(muted);

  useEffect(() => {
    mutedRef.current = muted;
  }, [muted]);

  // Will not connect until socketUrl is set to non-null value
  const ws = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      // Torrap times out after a time period, user must make action
      // to reconnect. This is to prevent excessive stt use.
      if (!muted) {
        if (OVERRIDE_TIMEOUT_POPUP) {
          console.log("overriding popup and reconnecting");
          return true;
        }

        alert("Session timed out, click ok to reconnect");
        return true;
      } else {
        return false;
      }
    },
    onClose: () => {
      clearTimeout();
      console.log("stt disconnected");
    },
    onOpen: () => {
      resetTimeout();
      console.log("stt connected");
    },
    reconnectInterval: 0,
  });

  useEffect(() => {
    // Set socket url when sample rate is found
    // this will connect the websocket connection
    if (sampleRate !== null && initialized !== false && muted === false)
      setSocketUrl(`${TORRAP_URL}/${sampleRate}`);
    // likewise unsetting it disconnects
    else setSocketUrl(null);
  }, [sampleRate, initialized, muted]);

  const init = (): void => {
    if (micStream.current?.stream)
      // If already set, skipp
      return;
    getUserMedia({ video: false, audio: true })
      .then((stream: any) => {
        console.log("Setting stream!");
        micStream.current = new MicrophoneStream({
          stream: stream,
          objectMode: false,
        });

        // Save sample rate so we can initialize websocket connection
        micStream.current.on("format", function (format: any) {
          console.log(format);
          setSampleRate(format.sampleRate);
        });
        micStream.current.on("data", function (chunk: any) {
          if (!mutedRef.current) {
            var raw = F32ToInt16(MicrophoneStream.toRaw(chunk));
            ws.sendMessage(raw);
          }
        });
        setInitialized(true);
      })
      .catch(function (error: any) {
        console.log(error);
        alert("You must allow microhpone");
      });
  };

  useEffect(() => {
    const data: string | null = ws.lastMessage?.data;
    if (data) {

      // XXX: this is a fugly hack made in the last minute before Ganni fashion show 2023
      const ganniReplaced = data.replace(/catty|Catty|Johnny|johnny|Kenny|kenny|Benny|benny|Candy|candy|Daniel|daniel|David|david|Janny|janny|Jenny|jenny|Guinea|guinea|Danny|danny|Canada|canada|County|county|galleys|Galleys|Gannon|gannon|Gana|gana|Janice|janice|Chinese|chinese|Gabbie|gabbie|Gandy|gandy|ganmi|Gammy|gammy|Dani|dani|Connie|connie|Barrie|barrie|Chun Li|chun li|danny|donnie|granny|guy nico|danny|gun|Ganas|ganas|Annie|annie|Andy|andy|gani|Gani|Canada|canada|gandy|Gandy|canon|Canon|Denny|denny|candies|Candies|Janet|janet|negro|Getty|getty|Katniss|katniss|scanning|Scanning|Kenya|kenya|Kani|kani|donny|Donny/g, 'Ganni');
      const ditteAndGanniReplaced = ganniReplaced.replace(/Steven|steven|Jesus|jesus|Ricky|ricky|Jared|jared|Jaden|jaden|Gina|gina|Jesus|jesus|Judith|judith|Dida|dida/g, 'Ditte');
      const ditteReffstrupAndGanniReplaced = ditteAndGanniReplaced.replace(/deep sea fish|DDOS Coke|to cold pizza desktop|do the best pull-up|dudas/g, 'Ditte Reffstrup');
      const ditteAndGirlAndGanniReplaced = ditteReffstrupAndGanniReplaced.replace(/catty girl|conical/g, 'Ganni Girl');

      setLastSTTResult(ditteAndGirlAndGanniReplaced);
    }
  }, [ws.lastMessage]);

  return { lastSTTResult, init, initialized };
};

export default useSpeechToText;
